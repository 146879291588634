<template>
  <div class="breadcrumbs">
   
    <!-- {{ breadcrumbs }} -->
    <ul v-if="breadcrumbs.length" class="bc flex text-sm animated animated--waiting" ref="breads_dom">
      <li v-for="(bc, key) in breadcrumbs" :key="key" class="bc__item pr-4">
       
        <NuxtLink :to="localePath(bc.url)">{{
          getShortenedName(bc.name)
        }}</NuxtLink>
      </li>
    </ul>
  </div>
</template>
<script setup>
//  const props = defineProps({ blok: Object })
import gsap from 'gsap'
const route = useRoute();
const breads_dom = ref(null)
// const { active_story } = usePageManager();
const { links, getHome, handleInternalLink } = useConfig();
const { data, cached_data,page_loading } = await useSettings("BC");
const { locale, locales } = useI18n()
const a_story = useState("active-story");
let breadcrumbs = [];
// console.log(cache.value)
// const bcs = useState('bcs', () => {
//   // var active_story = useState("active-story")
//    var page,
//     ret = [];
//   page = data.value.home;
//   //  ret.push({name: page.name,url:"/"})
//   console.log("updating breadcrumbs!")
//   route.params.slug != "" &&
//     ret.push({ name: page.name, url: "/" }) &&
//     route.params.slug.forEach((s) => {
//       page =
//         getRealStory(s) || (s == a_story.value.slug && a_story.value);
//       //  console.log(page)
//       if (page)
//         ret.push({ name: page.name, url: handleInternalLink(page.full_slug) });
//     });
//     return ret
// })
// console.log(bcs.value)
// console.log(data.value.home)
function createBreadcrumbs() {
  let crumbs = [];
  let page = cached_data.value.home;
  // console.log("createBreadcrumbs : ",locale.value)
  // console.log(cached_data.value?.links)
  route.params.slug != "" &&
    // crumbs.push({ name: page.name, url: "/" }) &&
// console.log(cached_data.value.links)
    route.params.slug.forEach((s) => {
      // cached_data.value.links.forEach(l => {
        
      //   console.log(l.slug, s)
      // })
      page = cached_data.value.links.find(l => l.slug == s) || (s == cached_data.value.active_story.slug && cached_data.value.active_story.value);
      //  console.log(s,page)

      if (page) {
        var translabel = page.translated_slugs?.find(ts => ts.lang == locale.value)?.name;
        

        // console.log(translabel || page.content?.title || page.content.label );
        // console.log(page.full_slug,handleInternalLink(page.full_slug,true))
        // console.log(translabel,page)
        
        crumbs.push({
          name: translabel || page.name ,
          url: handleInternalLink(page.full_slug),
        });
      }
    });
    // console.log(crumbs)
  return crumbs;
}
// console.log(createBreadcrumbs())
function getShortenedName(name) {
  // console.log("getShortenedName",name)
  return (name?.length > 30 && name.substring(0, 30) + "...") || name;
}
function getRealStory(slug) {
  var ret = cached_data.value?.links?.find((p) => p.slug == slug);
  //  console.log(cached_data.value.active_story.uuid == ret.uuid && "got it! : ", cached_data.value.active_story.content.title)
  // console.log(cached_data.value.active_story.slug,slug)
  return cached_data.value.active_story.slug == slug && cached_data.value.active_story ||  ret 
}


breadcrumbs = createBreadcrumbs();
// breadcrumbs = createBreadcrumbs();
// watch(
//   page_loading,
//   (value) => {
//     console.log("here",value)
//     if (!page_loading) {
//       breadcrumbs = createBreadcrumbs();
//     }

//   },
//   { deep: true, immediate: true }
// );
// // await useAsyncData('active-story',() => active_st)
//     // menu.isOpen = false
//     console.log("cache change", value,cache.value.links)
//     if(!cache.value.links)return

//   // var active_story = useState("active-story")

//    var page,
//     ret = [];
//   page = data.value.home;
//   //  ret.push({name: page.name,url:"/"})
//   console.log("updating breadcrumbs!",route.params.slug)
//   route.params.slug != "" &&
//     ret.push({ name: page.name, url: "/" }) &&
//     route.params.slug.forEach((s) => {
//       page =
//         getRealStory(s) || (s == a_story.value.slug && a_story.value);
//       //  console.log(page)
//       if (page)
//         ret.push({ name: page.name, url: handleInternalLink(page.full_slug) });
//     });
//   // return ret
//   breadcrumbs = ret;

// }, { deep: true, immediate: true })
// if (process.client) {
//     console.log("is client!")
//   }
// const breadcrumbs = useState('bcs')
/*computed(() => {
  var page,
    ret = [];
  page = data.value.home;
  //  ret.push({name: page.name,url:"/"})
  route.params.slug != "" &&
    ret.push({ name: page.name, url: "/" }) &&
    route.params.slug.forEach((s) => {
      page =
        getRealStory(s) || (s == active_story.value.slug && active_story.value);
      // console.log(page)
      if (page)
        ret.push({ name: page.name, url: handleInternalLink(page.full_slug) });
    });
  // console.log(route.params.slug)
  if (bcs.value.length) return bcs
  bcs.value = ret
  return ret;
});*/
onMounted(() => {
  if (process.client && breads_dom.value) {
    gsap.from(breads_dom.value.querySelectorAll('li'), { opacity: 0,  ease: "expo.out", duration: 1.5, stagger: .2 })
    breads_dom.value.classList.remove("animated--waiting")
  }
})
</script>
<style lang="scss">
.bc {
  position: absolute;
  &__item {
    position: relative;
    &:not(:last-child) {
      &::after {
        content: "/";
        position: absolute;
        // font-weight: bold;
        right: 0.25rem;
        pointer-events: none;
        // font-size: .75rem;
        // top:.15rem;
      }
    }
  }
}
</style>